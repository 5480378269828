import { getFirestore, collection, addDoc, query, where, orderBy, limit, getDocs } from 'firebase/firestore';

import { snapshotToArray } from './fb-helpers';
import { firebase } from './firebase';

const firestore = getFirestore(firebase); // Initialize Firestore with your Firebase app

export function saveUserAction(info) {
  return addDoc(collection(firestore, 'actions'), { ...info, date: new Date().toISOString() });
}

export async function getUserActions({ limit: limitValue = 200, orderByField = 'date', queries = [] }) {
  const firestoreQuery = query(
    collection(firestore, 'actions'),
    ...queries.map((q) => where(q.key, q.operator, q.value)),
    orderBy(orderByField, 'desc'),
    limit(limitValue)
  );

  const querySnapshot = await getDocs(firestoreQuery);
  return snapshotToArray(querySnapshot);
}
